import React, { useState, useContext, useReducer} from 'react';
import axios from 'axios';

//Context
import { AuthContext } from '../context/AuthContext';
import { DevContext } from '../context/DevContext';
import { AxiosContext } from '../context/AxiosContext';

//Custom Components
import BWLogo from '../components/BWLogo'
import Input from '../components/Input';
import Button from '../components/Button';
import ButtonLink from '../components/ButtonLink';

//Page Style
import styled from 'styled-components';
import '../CSS/Registration.scss'
import ErrorMsg from '../components/ErrorMsg';

const Registration = props => {
  //Context
  const authObj = useContext(AuthContext);
  const { login } = authObj

  const devObj = useContext(DevContext)
  const { logToDev } = devObj;

  const axiosObj = useContext(AxiosContext);
  const { public_end_point } = axiosObj;

  //State
  const [state, setState] = useReducer(
    (state, updates) => ({
        ...state,
        ...updates,
    }),
    {user_name:'',password:'',confirm_password:''}
    );
  const [errorMsg, setErrorMsg] =useState(null)
  const [validationMsg, setValidationMsg] = useReducer(
    (state, updates) => ({
        ...state,
        ...updates,
    }),
    {email:'',password:'',confirm_password:''}
    );

  const validated = props =>{
    let return_value = true
      if(!state.user_name){
        setValidationMsg({email:'Enter valid email'})
        return_value = false
      }
      if(!state.password){
        setValidationMsg({password:'Enter valid Password'})
        return_value = false
      }
      if(!state.confirm_password){
        setValidationMsg({confirm_password:'Please Confirm Password'})
        return_value = false
      }else if(state.password !== state.confirm_password){
        setValidationMsg({confirm_password:'Passwords don\'t match'})
        return_value = false
      }
    return return_value
  }
  const handleSubmit = async (e) =>{
    logToDev({click:'registration click'})
    if(validated()) {
        const response = await public_end_point.post('/registration',{state})
        logToDev({registration_response:response.data})

        if(response.data.error){
        logToDev({error:response.data.error})
        setErrorMsg(response.data.error)
        setState({user_name:'',password:'',confirm_password:''});
        }
    } else {
        setState({user_name:'',password:'',confirm_password:''});
    }
    // login({state})
  }
  return(
    <div className='background'>
      <div className='container'>
        <BWLogo front="Sign" back="Up"/>
        <Input type="text" 
          name='user_name'
          alt_placeholder = 'Your Email'
          error = {validationMsg.email}
          setValue={setState}
          value={state.user_name}/>
        <Input type="password" 
          name='password'
          error ={validationMsg.password}
          setValue={setState}
          value={state.password}/>
        <Input type="password" 
          name='confirm_password'
          error ={validationMsg.confirm_password}
          setValue={setState}
          value={state.confirm_password}/>
        <Button onClick={handleSubmit}>
          Register
        </Button>
        <HorizontalLine/>
        <ButtonLink link="login" content="Return to Login"/>
        {/* <ButtonLink link="help" content="Get Help"/> */}
      </div>
      {errorMsg && (
        <ErrorMsg error = {errorMsg} setErrorMsg={setErrorMsg}/>
      )}
    </div>
  )
}
export default Registration

const HorizontalLine = styled.hr`
  width: 90%;
  height: 0.7rem;
  border-radius: 0.8rem;
  border: none;
  background: rgb(21,91,153);
  background: linear-gradient(67deg, rgba(21,91,153,1) 0%, rgba(0,58,80,1) 94%);
  backdrop-filter: blur(25px);
`;
