import React, { useEffect, useState, useContext} from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

//Style
import './CSS/App.scss'

//Custom Components
import Homepage from './views/Homepage';
import NavBar from './components/NavBar'
import Spinner from './components/Spinner';

//Context
import { AuthContext } from './context/AuthContext';

//Test Views
import CloudStateExample from './components/CloudStateExample';
import LayoutTest from './components/LayoutTest';
import Blankcomponent from './components/Blankcomponent';

//Views
import Login from './views/Login';
import Registration from './views/Registration';
import Logout from './views/Logout';
import EditorPage from './views/Editorpage'
import Casepage from './views/Casepage';

//Custom Hooks

function App() {
  //Context
  const authObj = useContext(AuthContext);
  const { authState, loading } = authObj;
  const { authenticated } = authState

  if (loading){
    return <Spinner/>
  }
  return ( 
    // authenticated ?
    <>
      <Routes>
        <Route path='*' element={<Navigate to="/" />} />
        <Route path="/" exact element={<Login/>} />
        {/* <Route path="/example" exact element={<Blankcomponent/>} />
        <Route path="/cloudstate" exact element={<CloudStateExample/>} />
        <Route path="/test" exact element={<LayoutTest/>} />
        <Route path="/editor" exact element={<EditorPage/>} />
        <Route path="/logout" exact element={<Logout/>} />
        <Route path="/casepage" exact element={<Casepage/>} /> */}

      </Routes>
    </>
    // :
    // <>
    // <Routes>
    //     <Route path='*' element={<Navigate to="/login" />} />
    //     <Route path="/login" exact element={<Login/>} />
    //     <Route path="/registration" exact element={<Registration/>} />
    //   </Routes>
    // {/* <LayoutTest/> */}
    // </>
  );
}
export default App;
