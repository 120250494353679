import React, { useState, useContext, useReducer} from 'react';

//Context
import { AuthContext } from '../context/AuthContext';
import { AxiosContext } from '../context/AxiosContext';
import { DevContext } from '../context/DevContext';

//Custom Components
import BWLogo from '../components/BWLogo'
import Input from '../components/Input';
import Button from '../components/Button';
import ButtonLink from '../components/ButtonLink';
import ErrorMsg from '../components/ErrorMsg';

//Page Style
import styled from 'styled-components';
import '../CSS/Login.scss'



const Login = props => {

  //context objects
  const authObj = useContext(AuthContext);
  const { login } = authObj;

  const devObj = useContext(DevContext)
  const { logToDev } = devObj;

  const axiosObj = useContext(AxiosContext);
  const { public_end_point } = axiosObj;

  //State
  const [state, setState] = useReducer(
    (state, updates) => ({
        ...state,
        ...updates,
    }),
    {user_name:'',password:''}
  );
  const [errorMsg, setErrorMsg] =useState(null)
  const [validationMsg, setValidationMsg] = useReducer(
    (state, updates) => ({
        ...state,
        ...updates,
    }),
    {email:'',password:''}
  );

  const validated = props =>{
    let return_value = true
      if(!state.user_name){
        setValidationMsg({email:'Enter valid email'})
        return_value = false
      }
      if(!state.password){
        setValidationMsg({password:'Enter valid Password'})
        return_value = false
      }
    return return_value
  }

  const handleSubmit = async (e) =>{
    logToDev({click:'login click'})

    if(validated()){

      const response = await public_end_point.post('/login',{state})
      logToDev({validated_response:response.data})

      if(response.data.error){
        logToDev({error:response.data.error})
        setErrorMsg(response.data.error)
        setState({user_name:'',password:''})
      }
      if(response.data.authenticated){
        login(response.data)
      }
    }else{
      setState({user_name:'',password:'',confirm_password:''});
    }

  }
  return(
    <div className='background'>
      <div className='container'>
        <BWLogo front="Wel" back="come"/>
        <>Please use Login link</>
        <HorizontalLine/>
      </div>
    </div>
  )
}
export default Login

const HorizontalLine = styled.hr`
  width: 90%;
  height: 0.3rem;
  border-radius: 0.8rem;
  border: none;
  background: rgb(21,91,153);
  background: linear-gradient(67deg, rgba(21,91,153,1) 0%, rgba(0,58,80,1) 94%);
  backdrop-filter: blur(25px);
`;
