import styled from "styled-components";
import React, {useState} from "react";

const Input = props => {

    const { type, value, setValue, name, alt_placeholder, error } = props

    const placeholder = 
      error ? error :
      alt_placeholder ? alt_placeholder : 
        name.replace(/_/g, ' ');

    const color = error ? '#b60a0a;' : '#010101;';  

    const changeHandler = (e) =>{
        const {name, value } = e.target
        setValue({
            [name]:value
          })
    }

    return <StyledInput color={color}
            type={type} 
            value={value}
            placeholder={placeholder}
            name={name} 
            onChange={changeHandler} 
            />;
}
export default Input

const StyledInput = styled.input`
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 2rem;
  text-align:center;
  height: 2rem;
  padding: 1rem;
  border: none;
  outline: none;
  color: black;
  font-size: 1rem;
  font-weight: bold;
  &:focus {
    display: inline-block;
    box-shadow: 0 0 0 0.2rem #ddd1c2;
    backdrop-filter: blur(12rem);
    border-radius: 2rem;
  }
  &::placeholder {
    color: ${props => props.color};
    font-weight: 100;
    font-size: 1rem;
  }
`;