import React, {createContext, useReducer, useEffect, useCallback, useState, useContext} from 'react';

import { AxiosContext } from './AxiosContext';

const CloudStateContext = createContext(null);
const {Provider} = CloudStateContext;

const CloudStateProvider = ({children}) => {

  const [cloudState, setCS] = useReducer(
      (state, updates) => ({
          ...state,
          ...updates,
      }),
      {reserved_context:'active'}
      );
  const setCloudState = props =>{
    setCS(props)
  };
    
  return (
    <Provider
      value={{
        cloudState,
        setCloudState

      }}>
        {children}
    </Provider>
  );
};

export {CloudStateContext, CloudStateProvider};