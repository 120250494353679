import React, {createContext, useReducer, useEffect, useCallback, useState} from 'react';

//Custom Hooks
import useWindowDimensions from '../CustomHooks/useWindowsDimensions';

//Custom Components
// import { ErrorMsg } from '../components/ErrorMsg'

//Create Context Provider
const DevContext = createContext(null);
const {Provider} = DevContext;

const DevProvider = ({children}) => {
    const [loading, setLoading] = useState(false)

    const { height, width } = useWindowDimensions();
    
    const [devState, setDevState] = useReducer(
        (state, updates) => ({
            ...state,
            ...updates,
        }),
        {
            isOn: false,
            width: width
            }
        );
    
    const devOutput = devState.isOn ? <div style={{paddingLeft:'1rem',paddingTop:'0.1rem',paddingBottom:'0.1rem', background:'yellow'}}>
    {Object.keys(devState).map((key, index) => ( 
        <p key={index}> {key} : {String(devState[key])}</p> 
      ))}
      </div>
      :
      null;

    const clearDevState = () =>{
      
    };
    const logToDev = props =>{
      const object = Object.entries(props);
      const key = object[0][0]
      let value = object[0][1]
      try {
        value = JSON.stringify(value)
      } catch {
        //
      }
      setDevState({[key]:value})
    }
  return (
    <Provider
      value={{
        devState,
        logToDev,
        clearDevState
      }}>
          {devOutput}
    {/* {devState && (
        <ErrorMsg error = {devState} setErrorMsg={setErrorMsg}/>)} */}
      {children}
    </Provider>
  );
};

export {DevContext, DevProvider};