import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css'

import {BrowserRouter} from "react-router-dom";
import { AuthProvider } from './context/AuthContext'
import { DevProvider } from './context/DevContext';
import { AxiosProvider } from './context/AxiosContext';
import { CloudStateProvider } from './context/CloudStateContext';

ReactDOM.render(
  <BrowserRouter >
  <React.StrictMode>
    <AxiosProvider>
      <DevProvider>
        <AuthProvider>
          <CloudStateProvider>
            <App />
          </CloudStateProvider>
        </AuthProvider>
      </DevProvider>
    </AxiosProvider>
  </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

