import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ButtonLink = props => {
    const { content, link } = props;
    
    const navigate = useNavigate();
    async function handlenav(path) {
        navigate(path, { replace: true });
        console.log('nav handled')
    }
    const handleClick = async e =>{
        console.log('reg click')
        handlenav('/'+link)
    }
    return (<StyledButton onClick={handleClick}> {content} </StyledButton>);
}
export default ButtonLink

const StyledButton = styled.button`
background: linear-gradient(to right, #14163c 0%, #03217b 79%);
text-transform: uppercase;
letter-spacing: 0.2rem;
width: 65%;
height: 3rem;
border: none;
color: white;
border-radius: 2rem;
cursor: pointer;
`;