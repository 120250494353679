import React, { useState } from 'react';
import { css } from "@emotion/react";
import BarLoader from "react-spinners/BarLoader";

const override = css`
  position: absolute;
  top: 30%;
  left: 42%;
`;

const LineLoader = () => {

  return (
  <div className="sweet-loading">
    
      <BarLoader color={'blue'} width={150} height={4} loading={true} css={override}  />
    </div>
  )
};

export default LineLoader;