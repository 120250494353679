import React from "react";
import styled from "styled-components";

const Button = props => {
    const { children, onClick } = props;

    return (
    <StyledButton onClick={onClick}>{children}</StyledButton>
    );
}

export default Button

const StyledButton = styled.button`
    background: rgb(21,91,153);
    background: linear-gradient(67deg, rgba(21,91,153,1) 0%, rgba(0,58,80,1) 94%);
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    height: 3rem;
    border: none;
    color: white;
    border-radius: 2rem;
    cursor: pointer;
`;