import React from "react";

import styled from "styled-components";


const BWLogo = props => {

  const { front, back, size} = props;


  const logo_size = size ? size : '3rem';

  return (
    <LogoBox>
        <LogoStart size={logo_size}>{front}</LogoStart>
        <LogoEnd size={logo_size}>{back}</LogoEnd>
    </LogoBox>
  )
}

export default BWLogo

const LogoBox = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`;
const LogoStart = styled.h2`
    color:white;
    font-size: ${props => props.size};
`;
const LogoEnd = styled.h2`
    color: black;
    font-size: ${props => props.size};
`;  