import React, {createContext, useReducer, useEffect, useCallback, useState} from 'react';
import axios from 'axios';
// import { ErrorMsg } from '../components/ErrorMsg'
const AxiosContext = createContext(null);
const { Provider } = AxiosContext;

const AxiosProvider = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [authToken, setAuthToken] = useState(null)
    
    const [axiosState, setAxiosState] = useReducer(
        (state, updates) => ({
            ...state,
            ...updates,
        }),
        {
            isOn: true,
            }
        );
    const baseUrl = 'https://mk26zthgh4.execute-api.us-east-2.amazonaws.com/prod';
    const public_end_point = axios.create({
        baseURL: baseUrl });
    const private_end_point = axios.create({
      baseURL: baseUrl });
    
    const add_auth_header = (props) =>{
      // console.log('header added')
      // console.log(props)
      setAuthToken(('Bearer ' + props));    
      // private_end_point.interceptors.request.use(config => {
      //   config.headers.post['Authorization'] = 'Bearer ' + props;;
      //   return config;
      // });
    }
  useEffect(() => {
    if(authToken){
      private_end_point.defaults.headers.common['Authorization'] = authToken;
    }
  }, [authToken]);
  //   customAxios.interceptors.request.use(
  //     (request) => requestHandler(request),
  //     (error) => errorHandler(error)
  // );
  return (
    <Provider
      value={{
        public_end_point,
        private_end_point,
        add_auth_header
      }}>
        {children}
    </Provider>
  );
};

export {AxiosContext, AxiosProvider};