import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { DevContext } from '../context/DevContext';

import '../CSS/Navigation.scss'
import styled from 'styled-components';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBars } from '@fortawesome/free-solid-svg-icons';

const CollapseMenue = props => {

    //context
    const devObj = useContext(DevContext)
    const { logToDev } = devObj;

    //Props
    const { Items } = props

    //State
    const [visable, setVisable] = useState(true)
    //Navigation Helper function
    const navigate = useNavigate();
    async function handlenav(path) {
        navigate(path, { replace: true });
    }

    const onClick = async (e)=>{
        handlenav(e.target.dataset.link)
        logToDev({click:'NavItemClick'+Items.name})
    }
    const expand_collapse = props =>{
        logToDev({menueVisable: visable})
        setVisable(!visable)
    }
    

    return(
        <div>
        <FontAwesomeIcon onClick={expand_collapse} className='daBurger' icon={faBars} size='xl' />

        <CollapsUL className='CollapsUL' hidden={visable}>
        {Items.map((item, index)=>
            <li key={index} 
            data-link={item.link}
            onClick={onClick}
            >
            {item.name}
            </li>
        )}
        </CollapsUL>
        </div>
     
    )
}

export default CollapseMenue

const CollapsUL = styled.ul`
    visibility: ${props => props.hidden};
`;  