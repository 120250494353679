import React, { useState } from 'react';
import { css } from "@emotion/react";
import RingLoader from "react-spinners/RingLoader";

const override = css`
  position: absolute;
  top: 30%;
  left: 42%;
`;

const Spinner = () => {

  return (
  <div className="sweet-loading">
    
      <RingLoader color={'blue'} loading={true} css={override} size={150} />
    </div>
  )
};

export default Spinner;