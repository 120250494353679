import React, {createContext, useReducer, useEffect, useCallback, useState, useContext} from 'react';

import { AxiosContext } from './AxiosContext';
import { DevContext } from './DevContext';

const AuthContext = createContext(null);
const {Provider} = AuthContext;

const AuthProvider = ({children}) => {

  //Context
  const axiosObj = useContext(AxiosContext);
  const { public_end_point, add_auth_header } = axiosObj;

  const devObj = useContext(DevContext);
  const { logToDev } = devObj;

  // State
  const [loading, setLoading] = useState(false)
    const [authState, setAuthState] = useReducer(
        (state, updates) => ({
            ...state,
            ...updates,
        }),
        {
            user: 'Guest',
            accessToken: null,
            refreshToken: null,
            authenticated: null,
            app_role: 0
          }
        );

  const logout = async () => {
    localStorage.removeItem("token"); 
    setAuthState({
      user: 'Guest',
      accessToken: null,
      refreshToken: null,
      authenticated: false,
    });
    setLoading(false)
  };

  const login = async (token) => {
    logToDev({login:token})
    const auth_token = {
      user: token.user,
      accessToken: token.accessToken,
      refreshToken: token.refreshToken,
      authenticated: token.authenticated,
    }
    setAuthState(auth_token);
    const store_item = JSON.stringify({
      user: token.user,
      refreshToken: token.refreshToken,
    })
    localStorage.setItem("token", store_item)
  }

  const getAccessToken = () => {
    return authState.accessToken;
  };

  const refreshAuth = useCallback(async () => {
    if(authState.authenticated){
      setLoading(false)
      logToDev({loading_status:false})
    }else{
      logToDev({loading_status:true})
      setLoading(true)
    }
    logToDev({refreshAuth1:'starting refresh'})
    const token = JSON.parse(localStorage.getItem("token"))
    logToDev({refreshAuth2:token})
    var refreshToken;
    var user;
    try {
      refreshToken = token.refreshToken
      user = token.user
    } catch{
      logToDev({refreshAuth3:'invalid token'})
      logout()
    }
    if(!authState.accessToken && token){
      logToDev({refreshAuth3:'no accesstoken in mem'})
      const response = await public_end_point.post('/session',{refreshToken})
      logToDev({refreshAuthResponse:response.data})
      const data = response.data
      if(data.authenticated){
        const auth_token = {
          user: token.user,
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          authenticated: data.authenticated,
        }
        setAuthState(auth_token);
        add_auth_header(data.accessToken)
        logToDev({refreshAuth4: 'access token in mem'})
      } else {
        logout()
      }
    }

  },[authState.accessToken])

  useEffect(() => {
    refreshAuth();
  }, [refreshAuth]);

  return (
    <Provider
      value={{
        authState,
        loading,
        getAccessToken,
        setAuthState,
        logout,
        login,
      }}>
      {children}
    </Provider>
  );
};

export {AuthContext, AuthProvider};